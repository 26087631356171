import React from "react";
import { withTranslation } from "react-i18next";
import Blog1 from "../../assets/blog1.png";
import Blog2 from "../../assets/blog2.png";
import Blog3 from "../../assets/blog3.png";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="blogs-block">
          <ul>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog1} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Coffee Tasting Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog2} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="trends">Trends</div>
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Coffee Tasting Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog3} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="trends">Trends</div>
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Vegetarian Pizza Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog1} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="trends">Trends</div>
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Vegetarian Pizza Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog2} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Vegetarian Pizza Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
            <li>
              <div className="blog">
                <div className="image"><img src={Blog3} alt="realConsumer" /></div>
                <div className="blog-date">
                  <div className="date">April 11, 2021</div>
                </div>
                <div className="name"><a href="#">Vegetarian Pizza Header</a></div>
                <div className="text">Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum Small text here lorem ipsum.</div>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const TranslatedBlog = withTranslation("translations")(Blog);
export { TranslatedBlog as Blog };