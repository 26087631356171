import React, { useRef } from "react";
import logo from "../../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import ResponsiveMenu from "react-responsive-navbar";
import { withTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import bars from "../../assets/bars.png";
import close from "../../assets/close.png";

const HeaderNavigaton = (props) => {
  const { t } = props;
  const menuRef = useRef(null);
  const onClickBookDemo = () => {
    props.history.push("/demo");
    menuRef.current.handleClick();
  }
  const handleMenuItemClick = () => {
    menuRef.current.handleClick();
  };
  return (
    <div className="header-main">
      <div className="header-container">
        <div style={{ display: "flex" }}>
          <Link to="/">
            <img src={logo} className="logo" alt="Logo" />
          </Link>
          <div className="toggle_header">
            <ul className="navigation-block">
              <li>
                <NavLink
                  exact
                  to="/"
                  className="Nav_link"
                  activeClassName="activeRoute"
                  activeStyle={{ color: "#3c476c" }}
                >
                  {" "}
                  {t("For businesses")}{" "}
                </NavLink>
              </li>
              <li className="right-border"></li>
              <li>
                <NavLink
                  exact
                  to="/tester"
                  className="Nav_link"
                  activeClassName="activeRoute"
                  activeStyle={{ color: "#3c476c" }}
                >
                  {" "}
                  {t("For testers")}{" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <ResponsiveMenu
          menuOpenButton={
            <div className="responsive-navbar-open">
              <img src={bars} alt />
            </div>
          }
          menuCloseButton={
            <div className="responsive-navbar-close">
              <img src={close} alt />
              <span>Close</span>
            </div>
          }
          changeMenuOn="992px"
          largeMenuClassName="large-menu-classname"
          smallMenuClassName="small-menu-classname"
          menu={
            <ul className="navigation-block">
              <div style={{ display: "none" }} className="toggle_header1">
                <li>
                  <NavLink
                    to="/"
                    exact
                    className="Nav_link"
                    activeClassName="activeRoute"
                    activeStyle={{ color: "#3c476c" }}
                    onClick={handleMenuItemClick}
                  >
                    {" "}
                    {t("For Businesses")}{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/tester"
                    exact
                    className="Nav_link"
                    activeClassName="activeRoute"
                    activeStyle={{ color: "#3c476c" }}
                    onClick={handleMenuItemClick}
                  >
                    {" "}
                    {t("For Testers")}{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="http://16.170.59.111.nip.io/"
                    exact
                    className="Nav_link"
                    activeClassName="activeRoute"
                    activeStyle={{ color: "#3c476c" }}
                    onClick={handleMenuItemClick}
                  >
                    {t("Blog")}
                  </NavLink>
                </li>
              </div>
              {/* <Dropdown className="d-inline-block language_toggler">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="language_toggler"
                >
                  {i18n.language === "en" ? "English" : "Norsk"}
                  <span className="language_toggler_arrow d-inline-block align-middle pt-1 pl-1">
                    <KeyboardArrowDownIcon />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="dropdown_options"
                    onClick={() => i18n.changeLanguage("en")}
                  >
                    {t("English")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown_options"
                    onClick={() => i18n.changeLanguage("no")}
                  >
                    {t("Norwegian")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <div className="navbar-button-block">
                {/* <li>
                  <NavLink
                    activeClassName="activeRoute" className="Nav_link"
                    to={`/price`}
                  >
                    {t("Pricing")}
                  </NavLink>
                </li> */}
                <li>
                  <a className="Nav_link" href={`http://16.170.59.111.nip.io/`} target="_blank">
                    {t("Blog")}
                  </a>
                </li>
                {
                  props.location.pathname !== "/tester" &&
                  <>
                    <li>
                      <Button className="button-with-border" onClick={() => window.location.href = "https://business.tryio.no/prelogin/login"}>
                        {t("Login")}
                      </Button>
                    </li>
                    <li className="book-btn">
                      <Button className="button-with-border book" onClick={onClickBookDemo}>
                        {t("Book a Demo")}
                      </Button>
                    </li>
                  </>
                }
              </div>
            </ul>
          }
          ref={menuRef}
        />
      </div>
    </div>
  );
};

const HeaderTranslated = withTranslation("translations")(HeaderNavigaton);
export { HeaderTranslated as HeaderNavigation }
