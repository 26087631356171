import React, { Component } from 'react';

export class CustomInput extends Component {
    onChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };
    render() {
        const { name, placeholder, value, icon, isValid, message, disabled, type } = this.props;
        return (
            <div className="custom-input-container">
                {icon}
                <input type={type ? type : 'text'} className={`custom-input ${isValid ? '' : 'is-invalid'}`} name={name} value={value} placeholder={placeholder} onChange={this.onChange} disabled={disabled}/>
                {
                    !isValid &&
                    <div class="invalid-feedback">
                        {message}
                    </div>
                }
            </div>
        );
    }
}