import React from "react";
import HomeSection from "../Home/Section";
import mobile from "../../assets/biltester/Phone.png";
import GooglePlay from "../../assets/biltester/PlayStore.png";
import Apple from "../../assets/biltester/AppStore.png";
import FooterWoman from "../../assets/bilFooterWoman.svg";
import tastefulRewards from "../../assets/tastefulRewards.png";
import { withTranslation } from "react-i18next";
import queryString from 'query-string';

class ForTester extends React.Component {

    componentDidMount = () => {
        const parsedLan = queryString.parse(this.props.location.search);

        if (parsedLan.lan) {
            if (parsedLan.lan === "en") {
                setTimeout(() => {
                    this.props.i18n.changeLanguage('en');
                }, 0)
            } else if (parsedLan.lan === "no") {
                setTimeout(() => {
                    this.props.i18n.changeLanguage('no');
                }, 0)
            }
        }
    }

    render() {
        const { t, i18n, location, history } = this.props;

        return (
            <>
                <div className="blitester">
                    <div className="blitester_mobile">
                        <img src={mobile} alt="image" />
                    </div>
                    <div className="blitester_content">
                        <h1 className={i18n.language === "en" ? "tester_header1" : "tester_header"} > {t("Test food and win a prize!")} </h1>
                        <p className="tester_header2">
                            {t("Taste and evaluate new food products to help food consumers create exactly what you like. Have a say in what products end up to grocery store shelves tomorrow.")}
                        </p>
                        <div className="app_link">
                            <a href="https://play.google.com/store/apps/details?id=com.tryio.application" target="_blank">
                                <img src={GooglePlay} alt="Apple" />
                            </a>
                            <a href="https://apps.apple.com/no/app/tryio/id1534102058" target="_blank">
                                <img src={Apple} alt="Apple" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="home-sustainable-block tasteful-block">
                    <div className="home-sustainable-inner">
                        <div className="home-sustainable">
                            <div className="home-sustainable-left">
                                <h4>{t("Tasteful rewards!")}</h4>
                                <img className="sustainable__img" src={tastefulRewards} alt="WomanImg" />
                                <p>{t("Each time you participate in a food test, you will get a chance to win a prize, such as a gift basket and a gift card. More exciting rewards are on their way!")}</p>
                            </div>
                            <div className="home-sustainable-right">
                                <img className="sustainable__img" src={tastefulRewards} alt="WomanImg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-howItsWork">
                    <h2>{t("Test food in 1, 2, 3")}</h2>
                    <div className="home-newTestRequest">
                        <div className="home-backgroundImgLine"></div>
                        <HomeSection
                            h4Text={t("Pick a test")}
                            h5Text={t("Browse through the test offers in the app and pick the ones you like.")}
                            imgNumber={"01"}
                            height="80px"
                            width="80px"
                        />
                        <HomeSection
                            h4Text={t("Receive or pick up the products")}
                            h5Text={t("Food producers will send products home to you or invite you to test location")}
                            imgNumber={"02"}
                            height="80px"
                            width="80px"
                        />
                        <HomeSection
                            h4Text={t("Test food and give feedback")}
                            h5Text={t("You give feedback in the app while testing.")}
                            imgNumber={"03"}
                            height="80px"
                            width="80px"
                        />
                    </div>
                </div>

                {/* Footer*/}
                <div className="home-footer-wrapper">
                    <div className="home-footer-block">
                        <img src={FooterWoman} alt="woman" className="home-footer__img mobile" />
                        <div className="home-footer__info">
                            <h2>{t("Curious?")}</h2>
                            <h4>
                                {t("Wondering how it actually works? Download the app and try it out yourself. It is absolutely free of charge and you get to try a lot of exciting products!")}
                            </h4>
                            <div className="store-images">
                                <a href="https://play.google.com/store/apps/details?id=com.tryio.application" target="_blank">
                                    <img src={GooglePlay} alt="GooglePlay" />
                                </a>
                                <a href="https://apps.apple.com/no/app/tryio/id1534102058" target="_blank">
                                    <img src={Apple} alt="Apple" />
                                </a>
                            </div>
                        </div>
                        <img src={FooterWoman} alt="woman" className="home-footer__img" />
                    </div>
                </div>
                {/* Footer*/}
            </>
        );
    }
}

const TranslatedTester = withTranslation("translations")(ForTester);
export { TranslatedTester as Tester };