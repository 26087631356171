export const doRequest = async (
  url,
  body,

) => {

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });
    
    if(!response.ok) {
       const errorText = await response.text()
       throw new Error(errorText)
    }
    return response.json();
    } catch (e) {
     console.log(e)
    }
};