import React from "react";
import { withTranslation } from "react-i18next";
import homeMainBannerImg from "../../assets/homeMainBannerImg.png";
import HomeSection from "./Section";
import queryString from 'query-string';
import ReactGA from 'react-ga';
import clock from "../../assets/smallClock.png";
import FooterWoman from "../../assets/homeFooterWoman.svg";
import sustainableImage from "../../assets/sustainableImage.png";

import NFRlogo from "../../assets/logo1.png";
import Inven2logo from "../../assets/logo2.png";
import UIOLOGO from "../../assets/logo3.png";
import InnovasjonNorgeLogo from "../../assets/logo4.png";
import LogoGrunder from "../../assets/logo5.png";

import TryioMotionGrapichSoscialMedia from "../../assets/TryioMotionGrapichSoscialMedia.mp4"
import realConsumer from "../../assets/realConsumer.svg";
import dataDriven from "../../assets/dataDriven.svg";
import higherMarket from "../../assets/higherMarket.svg";
import Button from '@material-ui/core/Button';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickBookDemo = () => {
    this.props.history.push("/demo");
  };

  componentDidMount = () => {
    const parsedLan = queryString.parse(this.props.location.search);

    if (parsedLan.lan) {
      if (parsedLan.lan === "en") {
        setTimeout(() => {
          this.props.i18n.changeLanguage('en');
        }, 0)
      } else if (parsedLan.lan === "no") {
        setTimeout(() => {
          this.props.i18n.changeLanguage('no');
        }, 0)
      }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollDown() {
    window.scrollTo({
      top: 750,
      behavior: "smooth",
    });
  }

  render() {
    const { t, i18n, location, history } = this.props;
    return (
      <>
        <div className="home-header-block">
          <div className="home-header__content">
            <div className="home-header-content__title">
              <h1>
                {t("Get better consumer insight, faster.")}
              </h1>
            </div>
            <p>
              {t("Access desired consumer groups, test products and get reliable results all in one platform")}
            </p>
            <div className="home-header__buttons-block">
              <Button className="registrationBtn" onClick={() => window.location.href = "https://business.tryio.no/prelogin/register"}>
                {t("Get started")}
              </Button>
              <Button className="buttons-block__read-more" onClick={this.onClickBookDemo}>
                {t("Book a Demo")}
              </Button>
            </div>
          </div>
          <img className="home-header__img" src={homeMainBannerImg} alt="homeMainBannerImg" />
        </div>

        <div className="home-logos-block">
          <div className="home-logos-inner">
            <ul>
              <li>
                <img src={NFRlogo} alt="NFRlogo" />
              </li>
              <li>
                <img src={Inven2logo} alt="Inven2logo" />
              </li>
              <li>
                <img src={UIOLOGO} alt="UIOLOGO" />
              </li>
              <li>
                <img src={InnovasjonNorgeLogo} alt="InnovasjonNorgeLogo" />
              </li>
              <li>
                <img src={LogoGrunder} alt="LogoGrunder" />
              </li>
            </ul>
          </div>
        </div>

        <div className="home-video-block">
          <div className="home-video-inner">
            <div className="home-video">
              <video loop autoPlay muted controls type="video/mp4">
                <source src={TryioMotionGrapichSoscialMedia} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="home-data-informed-block">
          <h2 className="d-md-block">{t("Data-informed decisions = Higher market success")}</h2>
          <h2 className="d-md-none">{t("Make data-informed decisions")}</h2>
          <div className="home-data-informed-inner">
            <ul>
              <li>
                <div className="data-block">
                  <div className="image"><img src={realConsumer} alt="realConsumer" width="180" /></div>
                  <div className="name">{t("Real consumer data")}</div>
                  <div className="text">{t("Test products with the right target group. Carefully selected representative consumer sample is always one click away.")}</div>
                </div>
              </li>
              <li>
                <div className="data-block">
                  <div className="image"><img src={dataDriven} alt="dataDriven" width="110" /></div>
                  <div className="name">{t("Data-driven development")}</div>
                  <div className="text">{t("You will no longer need to make assumptions. Highly reliable consumer insight will do the job for you.")}</div>
                </div>
              </li>
              <li>
                <div className="data-block">
                  <div className="image"><img src={higherMarket} alt="higherMarket" width="110" /></div>
                  <div className="name">{t("Higher market acceptance")}</div>
                  <div className="text">{t("Less guessing and more data-informed decisions will significantly increase your success rate on the market.")}</div>
                </div>
              </li>
            </ul>
            <div className="getstartedbtn">
                <Button className="getstarted" onClick={() => window.location.href = "https://business.tryio.no/prelogin/register"}>
                  {t("Get started")}
                </Button>
            </div>
          </div>
        </div>

        <div className="home-sustainable-block">
          <div className="home-sustainable-inner">
            <div className="home-sustainable">
              <div className="home-sustainable-left">
                <h4>{t("Sustainable food production")}</h4>
                <p>{t("Involving consumers into development from very early on leads to more effective product development and ensures that the products that come out on the market are the products that consumers will love and buy. This will significantly decrease daily food waste, which is a step forward towards sustainable food production.")}</p>
              </div>
              <div className="home-sustainable-right">
                <img className="sustainable__img" src={sustainableImage} alt="WomanImg" />
              </div>
            </div>
          </div>
        </div>

        <div className="home-howItsWork">
          <h2>{t("How it works")}</h2>
          <div className="home-newTestRequest">
            <div className="home-backgroundImgLine"></div>
            <HomeSection
              h4Text={t("Create a test")}
              h5Text={t("Define test type and criteria for desired consumer group.")}
              imgNumber={"01"}
              height="100px"
              width="80px"
            />
            <HomeSection
              h4Text={t("We recruit")}
              h5Text={t("We find suitable testers in our large consumer database.")}
              imgNumber={
                <div className="clockImageMain">
                  <img
                    className="clockImage"
                    src={clock}
                    alt="clock"
                  />
                </div>
              }
              height="80px"
              width="80px"
            />
            <HomeSection
              h4Text={t("Conduct testing")}
              h5Text={t("Invite testers to test location or send samples home to them.")}
              imgNumber={"02"}
              height="80px"
              width="80px"
            />
            <HomeSection
              h4Text={t("Receive feedback")}
              h5Text={t("Receive answers real time while consumers are testing.")}
              imgNumber={"03"}
              height="80px"
              width="80px"
            />
          </div>
        </div>

        {/* Footer*/}
        <div className="home-footer-wrapper">
          <div className="home-footer-block">
            <img src={FooterWoman} alt="woman" className="home-footer__img mobile" />
            <div className="home-footer__info">
              <h2>{t("Curious?")}</h2>
              <h4>
                {t("Wondering how Tryio would fit into your companys innovation process? Or how cost-efficient the platform is compared to its substitutes? Book a demo and we will answer to all of your questions.")}
              </h4>
              <Button className="footer-info__tryFree" onClick={this.onClickBookDemo}>
                {t("Book a demo")}
              </Button>
            </div>
            <img src={FooterWoman} alt="woman" className="home-footer__img" />
          </div>
        </div>
      </>
    );
  }
}

const TranslatedHome = withTranslation("translations")(Home);
export { TranslatedHome as Home };